.footer-link {
    text-decoration: none; /* Remove text decoration */
    color: #dbdcdd; /* Set default text color */
    display: flex; /* Align icon and text horizontally */
    justify-content: flex-start; /* Justify content to the left */
    align-items: center; /* Center vertically */
    margin-bottom: 8px; /* Add spacing between links */}
  
  .footer-link:hover {
    color: #808080; /* Set hover text color to white */
  }