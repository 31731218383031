/* Arrow Screen  */
.arrow {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    color: #000000;
    border-radius: 50px;
    text-align: center;
    font-size: 40px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
    background-color: white;
    z-index: 100;
    left: 15px;
  }
  .my-float1 {
    margin-top: 12px;
  }
  /* Toper realated */
  #hero1 {
    width: 100%;
  background: url("./nuc.jpg") top center; 
    background-size: cover;
    position: relative;
  }
  #hero h1 span {
    color: #ffffff;
  }