@media (min-width: 1024px) {
    #hero {
      background-attachment: fixed;
    }
  }
  
  @media (max-width: 500px) {
    #hero {
      min-height: 50vh;
    }
    #hero h1 {
      font-size: 28px;
      line-height: 36px;
    }
  
    #hero h2 {
      font-size: 20px;
      line-height: 24px;
    }
  }
  #hero {
    width: 100%;
    min-height: 100vh;
  background: url("../Images/nuc.jpg") top center; 
    background-size: cover;
    position: relative;
  }
  
#hero:before {
    content: "";
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
  }
  
  #hero .container {
    position: relative;
    padding-top: 0px;
    text-align: center;
  }
  
  #hero h1 {
    margin: 0;
    font-size: 50px;
    font-weight: 600;
    line-height: 64px;
    color: #fff;
    font-family: "Poppins", sans-serif;
  }
  
  
  
  #hero h2 {
    color: rgba(255, 255, 255, 0.9);
    margin: 10px 0 0 0;
    font-size: 24px;
  }
  
  