




/* # Breadcrumbs */

.breadcrumbs {
  padding: 15px 0;
background-color: #626262;
  min-height: 40px;
  margin-top: 0px;
}

.breadcrumbs h2 {
  font-size: 35px;
  font-weight: 400;
  color: white;
}

.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
}

.breadcrumbs ol li+li {
  padding-left: 10px;
}

.breadcrumbs ol li+li::before {
  display: inline-block;
  padding-right: 10px;
  color: #2f2f2f;
  content: "/";
}

@media (max-width: 992px) {

  .breadcrumbs .d-flex {
    display: block !important;
  }
  .breadcrumbs{
margin-top: 50px;
  }

  .breadcrumbs ol {
    display: block;
  }

  .breadcrumbs ol li {
    display: inline-block;
  }
}
.nav-link{
  text-decoration: none;
}
.active{
  font-weight: bold;
}
.highlighted {
  background-color: yellow;}

/*--------------------------------------------------------------
# Background images
--------------------------------------------------------------*/
input[type=text],input[type=number], select, textarea {
  width: 80%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 2px;
  margin-bottom: 0px;
  resize: vertical;

}
.ccc{
  display: flex;
}
.mm{
margin: 2px;
}

input[type=Submit] {
  background-color: #c3c3c3;
  color: white;
  padding: 5px 100px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

input[type=submit]:hover {
  background-color: #0d6efd;
}

/*--------------------------------------------------------------
Demo related class  Start
--------------------------------------------------------------*/
.cta {
  background: linear-gradient(rgba(2, 2, 2, 0.5), rgba(0, 0, 0, 0.5)), url("./Images//custom-welding-services-west-michigan-metal-fabrication.jpeg") fixed center center;
  background-size: cover;
  padding: 60px 0;
}
.bddrad{
  border-radius: 15px;
}
/* End */
.headerbd {
  background: linear-gradient(rgba(2, 2, 2, 0.5), rgba(0, 0, 0, 0.5)), url("./Images//custom-welding-services-west-michigan-metal-fabrication.jpeg") fixed center center;
  background-size: cover;
  padding: 40px 0;
}
.headerbd1 {
  background: linear-gradient(rgba(2, 2, 2, 0.5), rgba(0, 0, 0, 0.5)), url("./Images//custom-welding-services-west-michigan-metal-fabrication.jpeg") fixed center center;
  background-size: cover;
  padding: 40px 0;
  border-radius: 15px;

}
.mt{
  margin-top: 10px;
}




.cta h3 {
  color: #fff;
  font-size: 28px;
  font-weight: 700;
}

.cta p {
  color: #fff;
}

.conbtt{
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 28px;
  border-radius: 15px;
  transition: 0.5s;
  margin: 10px;
  border: 2px solid #acacac;
  background: #acacac;
  text-decoration: none;
}
.conbtt:hover {
  background: #323131;
  border-color: #ffffff;
}


.cta .cta-btn {
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 28px;
  border-radius: 4px;
  transition: 0.5s;
  margin-top: 10px;
  border: 2px solid #fff;
  color: #fff;
  text-decoration: none;
}

.cta .cta-btn:hover {
  background: #0d6efd;
  border-color: #ffffff;
  color: #ffffff;
}
.logo11{
  width: 100px;
  height: auto;
  filter: drop-shadow(5px 5px 8px #0d6efd);

}

.bgg{
background-color: transparent;
}


.counts .content {
  padding: 30px 0;
}

.counts .content h3 {
  font-weight: 700;
  font-size: 34px;
  color: #151515;
}

.counts .content p {
  margin-bottom: 0;
}

.counts .content .count-box {
  padding: 20px 0;
  width: 100%;
}

.counts .content .count-box i {
  display: block;
  font-size: 36px;
  color: #ffc451;
  float: left;
}

.counts .content .count-box span {
  font-size: 36px;
  line-height: 30px;
  display: block;
  font-weight: 700;
  color: #151515;
  margin-left: 50px;
}

.counts .content .count-box p {
  padding: 15px 0 0 0;
  margin: 0 0 0 50px;
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  color: #3b3b3b;
}

.counts .content .count-box a {
  font-weight: 600;
  display: block;
  margin-top: 20px;
  color: #3b3b3b;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  transition: ease-in-out 0.3s;
}

.counts .content .count-box a:hover {
  color: #626262;
}

.counts .image {
  background: url("./Images/custom-welding-services-west-michigan-metal-fabrication.jpeg") center center no-repeat;
  background-size: cover;
  min-height: 400px;
  border-radius: 20px;
}

@media (max-width: 991px) {
  .counts .image {
    text-align: center;
  }

  .counts .image img {
    max-width: 80%;
  }
}

@media (max-width: 667px) {
  .counts .image img {
    max-width: 100%;
  }
}

/*** Topbar Start ***/

.topbar .top-info {
  letter-spacing: 1px;
}

.topbar .top-link {
  display: flex;
  align-items: center;
  justify-content: center;
}

.topbar .top-link .a112 {
  margin-right: 10px;
}

#note {
  width: 400px;
  overflow: hidden;
}

#note small {
  position: relative;
  display: inline-block;
  animation: mymove 5s infinite;
  animation-timing-function: all;
}
@media (max-width: 300px) {
  .navbar-nav {
    flex-direction: column;
    align-items: center;
    background-color: #f8f9fa; /* Add a background color for better visibility */
  }

  .navbar-toggler {
    display: block;
  }

  .navbar-collapse {
    display: none;
    width: 100%;
  }

  .navbar-nav.ms-auto {
    text-align: center;
  }

  .navbar-nav.ms-auto.show {
    display: flex;
    flex-direction: column;
  }

  .navbar-nav.ms-auto .nav-link {
    padding: 10px;
    border-bottom: 1px solid #ccc; /* Add a border between links */
  }
}

@keyframes mymove {
  from {left: -100%;}
  to {left: 100%;}
}


