/* Services Page */
.services .icon-box .icon i {
    color: #000000;
    font-size: 60px;
    transition: ease-in-out 0.3s;
  }
  .services .icon-box h4 {
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 24px;
  }
  
  .services .icon-box h4 .a1a1 {
    color: #151515;
    transition: ease-in-out 0.3s;
    text-decoration: none;
  }
  .services .icon-box h4 .a1a1:hover {
    color: rgba(13, 110, 253, 1);
  }
  
  .services .icon-box p {
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
  }
  
  .services .icon-box:hover {
    border-color: #fff;
    box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1);
    transform: translateY(-10px);
  }
  .services .icon-box {
    text-align: center;
    border: 1px solid #ffffff;
    padding: 80px 20px;
    transition: all ease-in-out 0.3s;
    background: #e2e1e1;
    border-radius: 30px;
  }
  
  .services .icon-box .icon {
    margin: 0 auto;
    width: 80px;
    height: 80px;
    background: transparent;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    transition: 0.3s;
  }