.b11{
    width: 100%;
    border-radius: 40px;
    margin-left: auto;
    margin-right: auto;
    }
    .webimgdiv{
 width: 50%;
    }
   .webtextjus{
    text-align: justify;
    font-weight: normal;
   } 
    .webdisf{
       display: flex;
    }
    .ddddf{
       display: flex;
    }
    .mobimgst{
        border-radius: 15px;
    
    margin-top: 10px;}
    .df{
   display: flex;
    }
    .p112{
     margin-left: 10px; color: black;
    }
  
    .szfont{
       color:rgb(3, 98, 222); font-size: 20px;
    }
    @media only screen and (max-width: 600px) {
       .webdisf{
          display: contents;
       } 
       .webimgdiv{
          width: 100%;
             }
    }

    .Softdisf{
        display: flex;
    }
    .softtextjus{
        text-align: justify;
        font-weight: normal;
    }
    .imgdiv{
    width: 50%;
    }
    
    .soft_img{
        width: 80%; 
    }
    
    .colll{
        color:rgb(3, 98, 222); font-size: 20px;
    }
    .pp{
        margin-left: 10px; color: black;
    }
    .mm1{
        text-align: center; border-color:orange; border-style: solid; border-radius: 20px; margin-top: 10px; width: 100%;  background-color: transparent;
    }
    .colror{
        color: rgb(3, 79, 96)
    }
    @media only screen and (max-width: 600px) {
        .Softdisf{
            display: contents;
        }
        .imgdiv{
            width: 100%;
            }
            .soft_img{
                width: 100%; 
            }
    }